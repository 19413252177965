<template>
  <div class="flex marginTop12">
    <div class="accountInfo">
      <div>
        <div class="font16 fontBlack85">Primary account</div>
        <div class="font12">110234567</div>
      </div>
      <div>
        <span class="font16">0.00</span
        ><span class="font12 marginLeft6">NZD</span>
      </div>
    </div>
    <div class="accountSelect"></div>
  </div>
</template>

<script>
export default {
}

</script>

<style scoped>
.accountInfo {
  width: 400px;
  height: 64px;
  background: #ffffff;
  border-radius: 2px 0px 0px 2px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 16px 24px 15px 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.accountSelect {
  box-sizing: border-box;
  width: 60px;
  height: 64px;
  background: #ffffff;
  border-radius: 2px 0px 0px 2px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-left: none;
  background: url("~@/assets/icon/down.png") no-repeat 16px / 24px 24px;
}
@media only screen and (max-width:600px){
.accountInfo {
  width: 100%;
  height: 8vh;
  background: #ffffff;
  border-radius: 2px 0px 0px 2px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 4vw 2vw;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.accountSelect {
  box-sizing: border-box;
  width: 15vw;
  height: 8vh;
  background: #ffffff;
  border-radius: 2px 0px 0px 2px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-left: none;
  background: url("~@/assets/icon/down.png") no-repeat center / 5vw 5vw;
}
}
</style>
