<template>
  <div>
    <div class="top-bar2 height275 flex flex-col justify-between">
      <div class="account-title">
        存入资金
        <div class="font14 marginTop32">
          要存入资金的账户：
          <BaseSelectionAccount></BaseSelectionAccount>
        </div>
      </div>
      <div class="nav-bar">
        <div
          :class="['bar-list', { is_active: currentTab === 'DepositeCards' }]"
          @click="currentTab = 'DepositeCards'"
        >
          信用卡/借卡记
        </div>
        <div
          :class="['bar-list', { is_active: currentTab === 'DepositeMethods' }]"
          @click="currentTab = 'DepositeMethods'"
        >
          本地充值方式
        </div>
        <div
          :class="['bar-list', { is_active: currentTab === 'DepositeVoucher' }]"
          @click="currentTab = 'DepositeVoucher'"
        >
          ieVoucher
        </div>
      </div>
    </div>
    <div>
      <keep-alive>
        <transition mode="out-in">
          <component v-bind:is="currentTab"></component>
        </transition>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import DepositeCards from '@/components/User/DepositeCards.vue'
import DepositeMethods from '@/components/User/DepositeMethods.vue'
import DepositeVoucher from '@/components/User/DepositeVoucher.vue'
import BaseSelectionAccount from '@/components/User/BaseSelectionAccount.vue'

export default {
  data () {
    return {
      currentTab: 'DepositeCards'
    }
  },
  components: {
    DepositeCards,
    DepositeMethods,
    DepositeVoucher,
    BaseSelectionAccount
  }
}
</script>

<style scoped>
.v-enter,
.v-leave-to {
  opacity: 0;
  transform: translateX(100px);
}
.v-enter-active,
.v-leave-active {
  transition: all 0.3s ease;
}

.height275 {
  height: 275px;
}
.accountInfo {
  width: 400px;
  height: 64px;
  background: #ffffff;
  border-radius: 2px 0px 0px 2px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 16px 24px 15px 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width:600px) {
.marginTop32{
  margin-top: 3vw;
}
.height275 {
  height: auto;
}
}
</style>
