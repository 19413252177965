<template>
  <div class="DepositeBottom">
    <div class="font24">ieVoucher本地充值</div>
    <form class="formWidth marginTop32" action="">
      <input class="voucherInput marginTop10" placeholder="PIN" type="text" />
      <input
        class="voucherInput marginTop10"
        placeholder="ieVoucher金额"
        type="text"
      />
      <input
        class="voucherInput marginTop10"
        placeholder="选择iemoney货币"
        type="text"
      />
      <div class="policyBox marginTop16">
        <input class="checkBoxStyle" type="checkbox" id="voucherPolicy" />
        <label class="voucherPolicy font14 fontBlack65" for="voucherPolicy"
          >我授权iemoney就此项存款获取我的个人资料，包括身份验证文件，并和iemoney的存款处理合作伙伴分享此等资料。</label
        >
      </div>
      <input class="voucherSub marginTop16" type="submit" value="继续" />
    </form>
  </div>
</template>

<script>
export default {

}

</script>

<style scoped>
.checkBoxStyle {
  background-color: #bc9a41;
  margin-right: 8px;
}

.voucherInput {
  width: 100%;
  height: 48px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  display: block;
  padding-left: 16px;
}
.DepositeBottom {
  padding-top: 25px;
  padding-left: 24px;
}
.voucherPolicy {
  display: block;
}
.formWidth {
  width: 440px;
  box-sizing: border-box;
}
.policyBox {
  display: flex;
  justify-content: space-between;
}
.voucherSub {
  width: 100%;
  height: 56px;
  background: #163257;
  border-radius: 4px;
  opacity: 0.2;
  box-sizing: border-box;
  border: none;
  outline: none;
  color: white;
  font-weight: 600;
}
@media only screen and (max-width:600px){
.DepositeBottom{
  padding: 5vw;
}
.marginTop10{
  margin-top: 1.3vh;
}
  .font24{
    font-size: 22px;
  }
.checkBoxStyle {
  background-color: #bc9a41;
  margin-right: 2vw;
  height: 10vw;
  width: 10vw;
}

.voucherInput {
  height: 7vh;
  background: #ffffff;
  padding-left: 3vw;
}
.voucherPolicy {
  display: block;
}
.formWidth {
  width: 100%;
  box-sizing: border-box;
}
.policyBox {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.font14{
  font-size: 12px;
}
.voucherSub {
  width: 100%;
  height: 7vh;
  background: #163257;
  border-radius: 4px;
  opacity: 0.2;
  box-sizing: border-box;
  border: none;
  outline: none;
  color: white;
  font-weight: 600;
}
.marginTop16{
  margin-top: 2vh;
}
}
</style>
