<template>
  <div class="cardContainer">
    <div class="flexBetween">
      <img class="bankImg" :src="imgSrc" alt="" />
      <div class="font16"><span class="black45">0-3个工作日</span></div>
    </div>
    <div class="divider marginTop24"></div>
    <div class="flexBetween marginTop16 font16 black45">
      <div class="black45">最少{{ atLeastFee }}NZD</div>
      <div class="black45">费用{{ fee }}%</div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      imgSrc: ''
    }
  },
  created () {
    // eslint-disable-next-line
    this.imgSrc = require(`@/assets/img/${this.imgProp}.png`)
  },
  props: {
    atLeastFee: {
      type: Number
    },
    imgProp: {
      type: String
    },
    fee: {
      type: Number
    }
  }
}

</script>

<style scoped>
.cardContainer {
  width: 400px;
  height: 169px;
  background: #ffffff;
  border-radius: 8px;
  padding: 24px;
  box-sizing: border-box;
}
.bankImg {
  width: 143px;
  height: 64px;
}
.divider {
  border-bottom: 1px solid #d9d9d9;
  height: 1px;
  background: #d9d9d9;
}
.flexBetween {
  align-items: center;
}
@media only screen and (max-width:600px){
.cardContainer {
  width: 100%;
  height: auto;
  background: #ffffff;
  border-radius: 8px;
  padding: 2vw 4vw;
  box-sizing: border-box;
}
.bankImg {
  width: 50vw;
  height: auto;
}
.divider {
  border-bottom: 1px solid #d9d9d9;
  height: 1px;
  background: #d9d9d9;
}
.flexBetween {
  align-items: center;
}
.marginTop24{
  margin-top: .7vh;
}
.black45{
  font-size: 16px;
}
.marginTop16{
  margin-top: .7vh;
}
}
</style>
