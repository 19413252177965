<template>
  <div class="DepositeBottom">
    <div class="font24">本地存储方式</div>
    <div class="font14 marginTop25">选择选项：</div>
    <div class="charginMethods">
      <BaseChoseBank class="bankUnit" v-for="(bank,id) in bankList"
     :key="id"
     :atLeastFee='bank.atLeastNum'
     :fee='bank.fee'
     :imgProp='bank.imgSrc'
     ></BaseChoseBank>
    </div>
  </div>
</template>

<script>
import BaseChoseBank from '@/components/User/BaseChoseBank.vue'

export default {
  data () {
    return {
      bankList: [
        {
          id: 0,
          imgSrc: 'logo_poli',
          atLeastNum: 18.04,
          fee: 1
        }
      ]
    }
  },
  components: {
    BaseChoseBank
  }
}

</script>

<style scoped>
.DepositeBottom{
  padding-top: 25px;
  padding-left: 24px;
}
.charginMethods{
  display: flex;
}
.bankUnit:not(:first-child) {
  margin-left: 16px;
}
@media only screen and (max-width:600px){
.DepositeBottom{
  padding: 5vw;
}
  .font24{
    font-size: 22px;
  }
  .marginTop25{
    margin-top: 1.5vh;
  }
}
</style>
